import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 830.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M0 5400 l0 -5400 4150 0 4150 0 0 5400 0 5400 -4150 0 -4150 0 0
-5400z m4045 2773 c16 -53 41 -99 72 -131 11 -12 8 -20 -17 -48 -16 -19 -32
-44 -35 -57 -13 -56 -23 -122 -19 -129 9 -15 25 -8 23 10 -4 27 11 93 27 117
21 33 67 50 93 35 17 -9 21 -20 20 -63 0 -50 -1 -51 -16 -29 -31 44 -81 20
-98 -49 -5 -21 -2 -27 14 -32 16 -4 21 -13 21 -36 l0 -31 -100 0 c-55 0 -100
3 -100 8 0 4 -3 17 -6 29 -5 18 -1 25 20 33 24 9 25 12 14 37 -27 63 -79 86
-93 42 -10 -29 -25 -6 -25 38 0 46 37 70 77 51 34 -16 74 -92 70 -131 -4 -28
6 -43 22 -33 11 7 -22 150 -40 173 -8 10 -20 22 -26 26 -19 12 -17 20 21 75
18 28 39 73 46 101 7 28 13 51 15 51 2 0 11 -26 20 -57z m-252 -432 c-3 -20
-8 -38 -10 -41 -9 -10 -50 55 -46 74 11 49 12 50 37 26 20 -18 23 -29 19 -59z
m508 -10 c-12 -12 -22 -27 -22 -33 -1 -7 -5 9 -10 36 -7 44 -5 51 14 67 22 17
22 17 30 -16 7 -27 5 -37 -12 -54z m-636 32 c0 -23 3 -45 8 -49 4 -4 7 -16 7
-27 0 -14 -4 -17 -17 -12 -41 17 -58 11 -61 -22 -6 -64 86 -116 148 -83 11 6
25 28 31 50 7 23 15 39 18 37 3 -2 24 -11 46 -21 35 -16 43 -17 65 -4 14 7 31
26 38 41 7 15 17 25 22 22 14 -8 -9 -63 -33 -80 -24 -17 -79 -20 -106 -5 -15
7 -23 2 -42 -27 -14 -21 -37 -41 -54 -46 -16 -5 -37 -16 -46 -24 -14 -12 -21
-8 -53 32 -66 80 -159 164 -208 187 -27 13 -47 27 -45 33 2 5 23 11 48 13 24
2 48 7 54 13 16 16 23 10 30 -30 9 -55 31 -72 46 -37 10 20 10 29 1 38 -21 21
-14 41 11 35 12 -4 29 -2 37 3 8 6 24 9 35 8 16 -3 20 -11 20 -45z m780 36 c4
-6 19 -7 37 -3 28 5 30 4 23 -12 -17 -40 -17 -71 -1 -77 18 -7 36 20 36 55 0
13 4 30 10 38 8 12 10 12 16 -3 5 -13 17 -17 50 -15 72 2 74 -15 6 -52 -42
-22 -89 -62 -153 -131 -68 -72 -95 -95 -101 -85 -4 7 -23 17 -41 20 -37 7 -61
28 -71 63 -6 19 -11 21 -26 13 -22 -12 -90 -13 -106 -2 -32 22 -58 92 -34 92
6 0 10 -7 10 -15 0 -21 48 -65 70 -65 9 0 33 11 53 24 39 27 47 25 47 -11 0
-37 42 -73 86 -73 28 0 44 8 70 34 37 37 43 66 19 86 -12 10 -21 10 -42 1 -31
-14 -41 -7 -25 19 7 11 12 40 12 65 0 41 2 45 24 45 14 0 28 -5 31 -11z m-385
-125 c12 -31 5 -81 -15 -108 -12 -16 -17 -17 -30 -6 -8 7 -15 20 -15 29 0 9
-4 21 -10 27 -6 6 -6 23 2 47 14 47 53 53 68 11z m311 -196 c4 -6 5 -17 2 -25
-4 -10 -71 -13 -349 -13 l-344 0 0 23 0 24 342 1 c235 0 345 -3 349 -10z m-1
-77 c3 -5 5 -31 5 -58 l0 -48 -347 -3 -348 -2 0 59 c0 33 4 61 10 63 23 7 675
-3 680 -11z m-754 -103 c23 -45 14 -110 -25 -164 -17 -24 -28 -44 -25 -44 3 0
36 18 74 41 234 139 504 143 750 12 84 -45 101 -51 87 -32 -79 108 -55 239 44
239 31 0 42 -5 56 -27 21 -32 24 -103 4 -126 -22 -27 -59 -22 -67 10 -8 31 7
59 30 57 23 -2 20 33 -4 46 -31 17 -60 2 -77 -37 -13 -32 -13 -41 2 -79 19
-50 63 -89 147 -130 61 -30 76 -28 59 4 -6 12 -11 40 -11 64 l0 43 19 -41 c24
-52 39 -62 50 -33 8 23 8 23 27 -6 l19 -29 5 34 6 35 16 -31 c31 -57 99 -113
158 -130 139 -40 243 -111 287 -198 74 -143 -3 -344 -148 -389 -69 -22 -115
-10 -158 38 -17 19 -32 35 -34 35 -2 0 7 -55 20 -122 42 -213 22 -502 -48
-713 -71 -212 -215 -420 -374 -538 -148 -110 -267 -155 -437 -164 -243 -13
-454 77 -635 271 -196 210 -302 475 -329 820 l-7 90 -22 -50 c-31 -68 -73
-105 -128 -111 -78 -9 -140 41 -153 123 -9 60 2 108 32 144 35 40 98 43 134 5
35 -37 41 -134 11 -164 -11 -11 -25 -21 -29 -21 -17 0 -41 26 -48 52 -5 23 -1
33 24 56 38 36 28 62 -24 62 -87 0 -99 -161 -15 -204 40 -21 69 -20 108 4 43
26 62 58 72 116 31 201 -198 292 -322 129 -63 -82 -81 -144 -82 -275 0 -137
23 -224 87 -335 52 -88 137 -197 149 -189 11 7 11 177 0 227 -5 20 -6 37 -1
37 21 0 115 -179 125 -237 9 -55 21 -55 36 -1 l11 43 33 -101 c33 -100 80
-205 117 -256 21 -31 254 -187 404 -272 56 -31 110 -66 119 -76 17 -19 17 -20
0 -20 -28 0 -222 106 -379 208 -49 32 -76 40 -76 24 0 -13 -158 -166 -206
-201 -107 -76 -184 -97 -304 -81 -142 18 -182 29 -244 63 -72 40 -81 57 -32
57 43 0 48 20 11 40 -35 19 -31 30 9 30 19 0 37 4 40 9 7 11 -16 71 -27 71 -4
0 -38 -24 -75 -54 -92 -74 -203 -146 -292 -191 -140 -71 -255 -59 -348 35 -88
89 -105 199 -51 322 37 84 34 122 -12 168 -41 41 -89 41 -130 1 -30 -31 -36
-59 -19 -92 16 -29 40 -24 40 9 0 27 15 52 31 52 16 0 39 -33 39 -56 0 -30
-41 -74 -69 -74 -29 0 -80 50 -82 80 -3 57 3 74 40 111 33 34 45 39 83 39 62
0 108 -40 128 -111 15 -52 17 -54 47 -51 47 5 86 -21 115 -75 61 -113 36 -243
-47 -243 -31 0 -65 50 -65 94 0 45 13 66 42 66 37 0 44 -60 10 -86 -23 -17 10
-39 37 -24 29 15 35 51 21 110 -17 70 -68 125 -107 115 -92 -22 -131 -150 -78
-254 28 -56 92 -115 143 -132 46 -15 138 -7 187 16 51 24 243 145 271 170 7 5
39 30 73 54 63 45 66 50 75 134 7 56 23 89 66 137 57 63 91 154 97 260 5 74 3
91 -16 128 -45 88 -141 118 -202 64 -27 -24 -33 -37 -36 -83 -6 -73 25 -122
79 -127 28 -2 40 2 52 20 23 33 21 52 -6 45 -44 -12 -75 47 -45 85 30 41 81
22 97 -36 28 -102 -57 -194 -139 -152 -92 48 -96 242 -7 298 32 20 109 21 150
3 17 -7 18 -5 12 21 -4 16 -11 31 -16 34 -19 11 -59 117 -71 183 -10 56 -10
90 0 171 15 121 26 147 35 85 4 -24 17 -65 28 -92 l22 -47 33 62 c57 109 140
168 235 168 41 -1 62 -7 93 -28 45 -31 84 -84 92 -124 3 -16 8 -28 12 -28 3 0
14 48 24 108 9 59 20 121 23 137 l6 30 -26 -32 c-38 -45 -101 -68 -154 -54
-140 38 -227 219 -177 370 20 62 93 137 168 173 58 29 140 58 161 58 15 0 107
87 139 130 l18 25 -5 -32 c-7 -39 5 -43 24 -8 15 28 30 33 30 9 0 -25 24 -11
39 24 27 60 41 32 20 -41 -6 -22 -8 -41 -6 -43 9 -9 115 47 155 81 69 59 82
139 30 178 -26 19 -28 19 -53 3 -29 -19 -29 -38 0 -42 51 -7 43 -84 -8 -84
-72 0 -57 160 16 172 33 6 75 -18 93 -54z m1608 -979 c55 -15 130 -90 167
-165 18 -35 35 -62 39 -59 13 8 41 74 49 118 l8 42 12 -29 c19 -46 25 -194 11
-260 -14 -70 -44 -143 -70 -171 -10 -11 -21 -30 -24 -42 -7 -22 -6 -22 57 -16
52 5 70 2 96 -13 61 -36 90 -147 57 -225 -40 -96 -138 -115 -186 -37 -50 83 9
218 71 162 41 -37 12 -123 -32 -96 -17 11 -19 9 -19 -17 0 -35 19 -52 58 -53
37 -1 48 6 68 44 20 40 23 108 4 142 -27 51 -94 70 -148 42 -67 -35 -102 -98
-102 -184 0 -86 51 -235 95 -280 37 -37 65 -103 65 -154 0 -26 3 -54 6 -62 7
-19 183 -158 294 -234 108 -73 163 -95 236 -96 55 -1 67 3 116 37 75 51 112
119 113 204 0 54 -4 66 -31 99 -39 49 -76 62 -110 39 -78 -51 -101 -215 -29
-215 28 0 32 12 10 30 -28 23 -13 80 21 80 23 0 44 -31 44 -64 0 -48 -33 -86
-74 -86 -52 0 -71 29 -71 109 0 120 66 207 151 199 36 -3 37 -2 49 47 32 124
168 148 235 40 24 -39 25 -75 5 -115 -30 -57 -80 -65 -119 -19 -29 34 -32 55
-12 82 28 36 72 6 59 -41 -4 -15 -1 -22 9 -22 44 0 52 73 12 114 -62 61 -164
12 -164 -80 0 -26 9 -62 21 -87 32 -62 36 -150 9 -217 -24 -61 -46 -89 -104
-131 -21 -16 -37 -29 -35 -30 4 -1 674 -10 722 -9 l27 0 0 -105 0 -104 50 -22
c67 -29 151 -120 181 -196 34 -86 33 -224 -1 -312 -32 -81 -92 -149 -169 -190
l-60 -32 -3 -102 -3 -102 -2855 0 -2855 0 -5 105 -5 105 -48 22 c-138 66 -227
248 -200 408 23 132 97 241 199 291 l54 26 0 104 0 104 328 0 c180 0 1240 1
2357 1 1117 1 2039 5 2050 9 19 7 19 7 -3 14 -32 11 -254 162 -308 211 -6 6
-27 25 -47 43 -20 17 -41 32 -46 32 -8 0 -31 -52 -31 -71 0 -4 16 -11 35 -15
41 -7 45 -21 10 -30 -37 -9 -25 -28 18 -32 20 -2 37 -6 37 -10 0 -14 -87 -60
-142 -76 -31 -9 -110 -19 -175 -22 -111 -6 -122 -5 -180 19 -96 39 -223 146
-305 258 -26 34 -49 36 -88 9 -88 -63 -375 -204 -398 -195 -19 7 -7 16 83 65
181 97 220 119 325 184 121 75 140 96 198 211 43 87 59 125 73 173 15 51 25
53 33 7 9 -46 22 -45 31 3 4 20 18 60 31 87 28 57 93 149 100 142 3 -2 0 -33
-6 -68 -12 -79 -13 -189 -1 -189 34 0 173 195 214 301 91 232 32 492 -129 570
-110 53 -234 -32 -234 -160 0 -65 18 -109 59 -144 75 -63 181 -9 181 92 0 67
-53 116 -99 91 -27 -14 -27 -27 1 -49 40 -33 20 -111 -28 -111 -43 0 -61 104
-27 160 32 55 99 66 140 23 32 -35 43 -64 43 -114 0 -91 -59 -159 -138 -159
-63 0 -101 25 -137 91 -96 176 45 379 229 328z m-3398 -945 c3 -19 4 -37 1
-40 -8 -9 -48 69 -42 84 8 23 33 -4 41 -44z m70 -8 c-24 -63 -37 -67 -29 -9 7
54 21 79 36 64 7 -7 4 -25 -7 -55z m124 24 c0 -24 -9 -35 -47 -62 -27 -17 -54
-38 -62 -46 -41 -40 -10 29 47 106 32 42 62 43 62 2z m4082 -126 c26 -38 48
-73 48 -77 0 -13 -33 10 -88 61 -48 45 -53 52 -42 72 6 11 17 20 23 18 7 -2
33 -35 59 -74z m62 54 c3 -13 5 -41 5 -63 l0 -40 -19 40 c-22 48 -25 85 -6 85
7 0 16 -10 20 -22z m91 1 c4 -5 0 -18 -8 -27 -7 -9 -18 -26 -22 -37 -8 -20 -9
-20 -12 1 -5 25 13 74 26 74 5 0 13 -5 16 -11z m-4197 -38 c-3 -22 -9 -27 -48
-33 -25 -5 -58 -7 -75 -6 -28 3 -26 5 35 39 70 40 93 40 88 0z m4008 -83 c30
-18 54 -36 54 -40 0 -10 -1 -10 -76 3 -47 8 -65 16 -69 30 -15 48 18 50 91 7z
m-4032 7 c8 -23 -19 -29 -57 -15 -54 22 -54 30 0 30 37 0 53 -4 57 -15z m4065
-75 c9 -1 -6 -10 -33 -20 -51 -20 -66 -19 -66 5 0 18 19 25 54 20 16 -3 36 -5
45 -5z m-3828 -30 c53 -23 126 -26 176 -5 43 18 43 18 43 1 0 -18 -77 -87
-135 -120 -70 -41 -123 -52 -175 -34 -24 8 -46 20 -48 27 -3 10 0 11 17 1 12
-6 34 -10 48 -8 24 3 21 7 -37 51 -34 27 -59 51 -56 54 3 3 27 -3 53 -14 105
-44 149 -35 94 19 -27 27 -39 48 -27 48 2 0 23 -9 47 -20z m3431 -87 c26 3 61
13 78 22 47 24 47 12 1 -33 -34 -33 -38 -41 -25 -46 21 -8 81 2 116 20 69 35
63 4 -7 -43 -61 -40 -61 -57 -2 -44 45 11 47 7 7 -14 -70 -36 -168 -3 -271 92
-77 71 -77 86 -1 60 39 -13 71 -17 104 -14z m-4328 -1744 c95 -307 153 -443
218 -510 53 -54 93 -72 164 -73 108 -2 174 65 174 174 0 56 -20 104 -49 120
-48 25 -110 3 -150 -56 -19 -27 -21 -38 -14 -61 5 -15 18 -30 28 -31 14 -3 17
1 11 21 -4 17 0 32 14 48 l20 24 20 -24 c27 -31 25 -67 -5 -96 -34 -34 -67
-32 -100 7 -22 26 -26 39 -22 71 8 56 48 111 101 137 57 27 95 21 140 -24 l32
-33 32 28 c39 35 91 38 139 9 42 -25 64 -70 70 -141 5 -54 4 -60 -25 -88 -38
-39 -65 -39 -103 -2 -48 48 -36 140 17 129 31 -6 41 -35 22 -68 -14 -24 -14
-31 -3 -40 34 -28 74 37 60 100 -9 39 -67 90 -105 90 -49 0 -79 -36 -91 -110
-13 -89 -26 -125 -56 -161 -105 -125 -306 -74 -405 103 -70 125 -220 580 -202
610 12 18 23 -7 68 -153z m5356 25 c-67 -248 -160 -475 -226 -553 -76 -88
-208 -125 -293 -81 -71 36 -100 89 -116 212 -8 64 -45 108 -90 108 -92 0 -154
-150 -79 -190 36 -20 50 -1 27 38 -20 35 -11 64 21 70 28 6 46 -18 46 -60 0
-30 -7 -46 -29 -69 -36 -35 -61 -37 -98 -5 -25 22 -28 30 -27 88 2 103 53 168
133 168 31 0 49 -7 75 -31 l35 -30 13 21 c14 24 77 60 104 60 34 0 104 -51
129 -94 36 -62 31 -119 -14 -153 -60 -46 -141 47 -93 106 l19 24 22 -22 c16
-16 21 -30 17 -47 -9 -34 21 -32 36 2 10 21 9 31 -7 60 -32 60 -106 90 -156
64 -54 -30 -67 -149 -23 -217 48 -73 116 -95 209 -68 111 32 177 133 273 420
28 83 60 184 72 225 26 89 29 95 42 82 6 -6 -2 -52 -22 -128z m-5316 82 c31
-53 106 -121 152 -138 19 -7 34 -15 34 -19 0 -12 -30 -19 -60 -12 -63 12 -20
-41 68 -84 29 -14 55 -29 58 -34 7 -12 -30 -11 -85 2 -24 5 -46 7 -49 4 -8 -8
17 -31 53 -49 18 -10 23 -16 13 -16 -77 0 -155 99 -198 251 -18 66 -26 139
-16 139 2 0 16 -20 30 -44z m5262 -36 c-39 -183 -124 -310 -207 -310 -23 0
-22 1 6 16 36 18 61 41 53 49 -3 3 -25 1 -49 -4 -98 -23 -119 -7 -36 27 53 23
80 42 107 76 14 18 13 19 -21 12 -36 -6 -69 0 -69 13 0 4 17 13 37 20 46 17
136 101 158 148 25 53 36 27 21 -47z m-3956 -100 c0 -73 4 -120 10 -120 6 0
10 -4 10 -10 0 -5 -20 -10 -45 -10 -25 0 -45 5 -45 10 0 6 5 10 10 10 6 0 10
42 10 105 0 63 -4 105 -10 105 -5 0 -10 4 -10 9 0 9 21 16 58 20 9 1 12 -29
12 -119z m1665 90 c0 -16 -6 -26 -18 -28 -19 -4 -32 20 -23 44 9 25 41 13 41
-16z m573 4 c3 -22 -21 -39 -39 -28 -11 6 -12 38 -2 48 13 13 38 1 41 -20z
m-2548 -34 c0 -13 9 -22 25 -26 29 -7 33 -24 6 -24 -23 0 -36 -45 -26 -90 5
-21 12 -30 27 -30 38 0 12 -25 -29 -28 -48 -4 -50 0 -55 100 -2 44 1 71 10 80
6 6 12 18 12 25 0 7 7 13 15 13 8 0 15 -9 15 -20z m2412 -2 c2 -17 9 -23 26
-22 12 0 22 -5 22 -13 0 -7 -11 -13 -25 -13 -24 0 -25 -3 -25 -60 0 -58 1 -60
26 -60 41 0 23 -25 -21 -28 -48 -4 -55 6 -55 85 0 38 -4 63 -12 65 -8 3 -4 12
10 26 12 12 22 27 22 32 0 20 29 9 32 -12z m-2896 -16 c14 -9 30 -175 18 -193
-9 -15 -53 119 -54 162 0 36 12 47 36 31z m4243 -9 c6 -13 2 -43 -15 -98 -13
-44 -24 -84 -25 -90 0 -5 -4 1 -9 15 -10 34 0 173 14 182 17 12 24 10 35 -9z
m-3561 -9 c15 -11 22 -25 22 -50 l0 -34 -50 0 c-54 0 -55 -1 -39 -31 7 -15 20
-20 42 -19 47 3 51 1 37 -15 -8 -9 -29 -15 -55 -15 -35 0 -48 5 -64 26 -31 39
-28 89 8 125 33 32 65 37 99 13z m280 0 c15 -11 22 -25 22 -50 l0 -34 -50 0
c-54 0 -55 -1 -39 -31 7 -15 20 -20 42 -19 49 3 54 1 33 -15 -29 -22 -92 -19
-116 5 -23 23 -28 97 -7 122 30 38 79 48 115 22z m166 10 c20 -8 22 -64 2 -64
-7 0 -16 9 -19 21 -8 29 -39 20 -48 -14 -4 -16 -3 -42 3 -59 9 -27 14 -29 45
-26 26 3 33 1 31 -11 -2 -12 -18 -17 -53 -19 -46 -3 -52 -1 -68 27 -47 81 23
177 107 145z m184 -18 c8 -8 16 -34 19 -57 7 -64 -22 -99 -83 -99 -56 0 -75
17 -82 75 -4 39 -1 47 27 76 28 27 37 31 68 26 20 -4 43 -13 51 -21z m104 13
c2 -7 12 -7 31 2 20 9 33 9 51 1 18 -8 34 -8 60 0 30 10 39 9 56 -7 16 -14 20
-31 20 -82 0 -35 4 -63 10 -63 6 0 10 -4 10 -10 0 -5 -21 -10 -47 -10 -37 0
-44 3 -35 12 14 14 16 107 2 128 -7 12 -13 13 -27 4 -18 -11 -21 -62 -8 -121
5 -21 2 -23 -35 -23 -35 0 -40 3 -36 18 10 31 10 112 -1 123 -5 5 -17 7 -26 3
-13 -5 -17 -19 -17 -65 0 -33 4 -59 10 -59 6 0 10 -4 10 -10 0 -5 -20 -10 -45
-10 -25 0 -45 5 -45 10 0 6 5 10 10 10 6 0 10 27 10 60 0 33 -4 60 -10 60 -17
0 -11 28 8 33 30 8 40 7 44 -4z m320 0 c2 -7 12 -7 31 2 20 9 33 9 52 1 17 -8
33 -8 51 -2 35 14 41 13 64 -10 16 -16 20 -33 20 -80 0 -36 4 -60 11 -60 5 0
7 -4 4 -10 -3 -5 -24 -10 -46 -10 -21 0 -39 5 -39 10 0 6 5 10 10 10 15 0 12
93 -3 114 -8 11 -19 15 -30 10 -13 -5 -17 -19 -17 -65 0 -33 4 -59 10 -59 6 0
10 -4 10 -10 0 -5 -17 -10 -37 -10 l-38 0 0 72 c0 66 -2 73 -20 73 -18 0 -20
-7 -20 -73 l0 -72 -38 0 c-32 0 -37 3 -33 18 8 28 10 111 3 118 -13 13 -7 32
11 37 30 8 40 7 44 -4z m309 -6 c5 -133 11 -149 42 -137 13 5 17 19 17 65 0
33 -4 59 -10 59 -5 0 -10 7 -10 15 0 19 59 21 65 3 2 -7 5 -48 7 -90 l3 -77
-71 0 c-69 0 -72 1 -82 27 -6 15 -13 54 -15 87 l-5 61 29 2 c22 2 29 -2 30
-15z m203 8 c4 -5 20 -5 41 2 29 9 38 7 55 -8 16 -14 20 -31 20 -82 0 -35 4
-63 10 -63 6 0 10 -4 10 -10 0 -5 -20 -10 -45 -10 -25 0 -45 5 -45 10 0 6 5
10 10 10 13 0 13 100 0 120 -7 12 -13 12 -27 4 -13 -8 -19 -26 -21 -68 -2 -34
1 -56 8 -56 5 0 10 -4 10 -10 0 -5 -20 -10 -45 -10 -25 0 -45 5 -45 10 0 6 5
10 10 10 6 0 10 26 10 59 0 33 -4 63 -10 66 -15 10 -12 23 8 28 29 8 41 7 46
-2z m216 -71 c0 -47 4 -80 10 -80 6 0 10 -4 10 -10 0 -5 -20 -10 -45 -10 -25
0 -45 5 -45 10 0 6 5 10 10 10 6 0 10 27 10 60 0 33 -4 60 -10 60 -17 0 -11
28 8 33 51 14 52 12 52 -73z m158 74 c18 -5 22 -13 20 -32 -4 -32 -28 -41 -35
-13 -4 15 -12 21 -27 19 -17 -2 -22 -11 -24 -46 -4 -54 10 -72 55 -72 30 0 34
-2 23 -15 -8 -9 -29 -15 -56 -15 -52 0 -74 25 -74 85 1 70 49 107 118 89z
m166 -10 c11 -11 16 -35 16 -80 0 -36 4 -64 10 -64 6 0 10 -4 10 -10 0 -6 -32
-9 -77 -7 -74 2 -78 3 -87 29 -12 35 13 66 57 70 21 2 33 9 35 21 3 14 -4 17
-42 17 -52 0 -60 13 -18 28 41 15 79 14 96 -4z m244 -67 c2 -42 7 -77 12 -77
6 0 10 -4 10 -10 0 -5 -20 -10 -45 -10 -25 0 -45 5 -45 10 0 6 5 10 10 10 6 0
10 27 10 60 0 33 -4 60 -10 60 -17 0 -11 28 8 33 42 12 47 5 50 -76z m174 63
c30 -23 39 -60 27 -103 -11 -40 -36 -56 -87 -57 -37 0 -45 4 -62 33 -23 37
-25 62 -7 97 25 49 87 63 129 30z m113 10 c4 -6 15 -6 31 0 62 24 84 1 84 -86
0 -36 4 -64 10 -64 6 0 10 -4 10 -10 0 -5 -20 -10 -45 -10 -25 0 -45 5 -45 10
0 6 5 10 10 10 12 0 13 100 1 119 -7 10 -13 11 -30 2 -17 -10 -21 -21 -21 -66
0 -30 5 -55 10 -55 6 0 10 -4 10 -10 0 -5 -17 -10 -38 -10 l-38 0 4 65 c3 43
0 70 -8 80 -10 12 -8 16 11 24 31 13 37 13 44 1z m263 1 c8 -4 12 -19 10 -32
l-3 -24 -21 23 c-13 14 -27 20 -38 16 -24 -9 -16 -23 29 -49 29 -16 41 -29 43
-49 5 -42 -13 -56 -74 -56 -51 0 -54 1 -54 25 0 27 24 35 34 10 7 -19 46 -20
46 -2 0 8 -17 25 -37 38 -48 30 -58 79 -21 97 28 13 68 15 86 3z m-3630 -116
c18 -43 30 -81 26 -84 -3 -3 -9 1 -14 9 -5 9 -22 31 -39 50 -47 54 -56 76 -41
95 21 25 33 13 68 -70z m4382 52 c0 -12 -102 -148 -107 -143 -7 6 57 160 69
167 13 9 38 -7 38 -24z m-4240 -20 c0 -23 -33 -96 -41 -91 -9 5 -2 52 12 92 9
23 29 22 29 -1z m4060 -38 c0 -15 3 -34 6 -43 3 -9 2 -16 -2 -16 -15 0 -47 89
-39 108 6 16 7 16 21 -2 8 -11 14 -32 14 -47z m-4230 -44 c42 -43 35 -53 -18
-26 -44 23 -56 36 -47 51 11 18 31 11 65 -25z m4425 15 c0 -8 -19 -25 -42 -38
-49 -27 -55 -16 -15 28 28 31 57 36 57 10z m-4177 -8 c4 -13 -66 -47 -77 -37
-8 9 48 57 62 52 6 -2 13 -9 15 -15z m3924 -12 c37 -33 29 -38 -26 -15 -35 15
-43 25 -29 38 11 12 22 8 55 -23z m-537 -199 l170 -6 -160 -10 c-215 -13
-2847 -11 -2980 2 l-100 10 120 3 c746 18 2483 18 2950 1z"/>
<path d="M3868 7115 c-159 -39 -302 -135 -438 -295 -74 -86 -154 -248 -195
-397 -49 -173 -58 -438 -21 -608 85 -393 325 -679 646 -770 78 -23 239 -25
320 -6 68 17 182 68 247 111 59 40 188 169 230 230 256 378 280 904 57 1303
-124 221 -309 373 -521 428 -90 23 -239 24 -325 4z m333 -89 c350 -108 599
-502 599 -946 0 -360 -161 -691 -418 -861 -130 -86 -241 -115 -402 -106 -295
17 -554 252 -670 605 -106 328 -58 707 127 985 119 180 310 311 498 341 52 8
218 -3 266 -18z"/>
<path d="M3893 7004 c-67 -14 -186 -69 -243 -111 -66 -49 -165 -160 -210 -234
-63 -102 -97 -182 -128 -302 -24 -92 -27 -120 -27 -277 0 -136 4 -192 18 -250
35 -142 102 -294 170 -385 117 -155 278 -265 435 -295 77 -16 239 -7 300 15
286 103 483 356 548 703 21 112 21 312 0 423 -92 489 -468 800 -863 713z m220
-496 c83 -25 137 -104 137 -203 0 -75 -19 -117 -77 -172 l-54 -53 32 -62 c48
-91 137 -226 179 -269 28 -30 45 -38 88 -44 41 -5 52 -11 52 -25 0 -32 -39
-50 -106 -50 -103 0 -142 40 -262 270 -42 82 -69 122 -93 138 -60 41 -64 34
-67 -128 -3 -168 3 -190 59 -215 21 -10 39 -24 39 -32 0 -11 -29 -13 -157 -11
-98 2 -158 7 -157 13 1 5 19 19 40 30 21 11 44 31 51 45 16 32 18 626 3 682
-8 29 -18 39 -45 48 -38 12 -50 24 -40 40 9 15 328 13 378 -2z"/>
<path d="M3973 6470 c-29 -12 -35 -58 -31 -230 l3 -135 40 -3 c30 -2 50 3 75
20 52 35 65 69 65 174 0 80 -3 95 -25 126 -15 23 -37 40 -61 47 -41 13 -37 12
-66 1z"/>
<path d="M3490 7040 c-36 -24 -133 -53 -267 -81 -79 -16 -168 -39 -197 -50
-76 -28 -162 -93 -186 -140 -58 -113 -18 -273 83 -329 57 -32 127 -24 170 19
31 30 32 34 32 115 0 70 -4 89 -21 113 -47 63 -101 67 -155 13 -35 -34 -38
-77 -9 -111 19 -23 20 -23 35 -5 20 26 47 14 43 -20 -4 -34 -27 -49 -61 -40
-56 14 -83 107 -52 175 18 40 75 81 114 81 75 0 123 -58 145 -177 l12 -65 33
79 c68 156 184 325 280 406 31 26 32 37 1 17z"/>
<path d="M4550 7044 c0 -3 30 -38 68 -78 67 -73 77 -85 121 -145 28 -39 113
-209 132 -265 l14 -39 6 69 c10 125 71 201 152 191 111 -15 162 -153 85 -234
-41 -44 -107 -22 -94 32 7 29 22 32 49 8 18 -17 19 -17 33 2 19 25 18 85 -1
112 -23 33 -88 50 -120 33 -66 -35 -95 -161 -56 -238 27 -54 69 -76 132 -70
61 6 106 40 139 104 21 40 25 63 25 131 0 74 -3 85 -30 125 -64 91 -158 134
-400 183 -72 14 -158 39 -192 55 -35 16 -63 27 -63 24z"/>
<path d="M2737 5553 c-4 -3 -7 -21 -7 -39 0 -18 -9 -61 -20 -96 -27 -88 -27
-88 26 -88 124 0 215 -116 201 -255 -12 -121 -103 -188 -187 -138 -36 21 -50
52 -50 109 0 37 6 51 29 75 34 33 61 37 84 11 20 -22 21 -45 6 -75 -12 -21
-12 -21 -35 8 -19 24 -25 27 -38 16 -40 -34 3 -121 60 -121 39 0 90 56 99 109
15 92 -45 193 -132 219 -113 34 -226 -84 -210 -220 9 -74 53 -161 107 -212
110 -103 224 -102 379 4 72 48 211 177 211 194 0 7 -28 31 -61 55 -129 91
-325 277 -406 386 -25 33 -46 61 -47 63 -2 1 -6 -1 -9 -5z m367 -417 c25 -24
61 -51 81 -60 42 -20 45 -33 9 -42 -15 -4 -62 -31 -107 -61 -44 -29 -82 -51
-85 -49 -7 8 28 58 66 93 l35 33 -26 52 c-25 50 -33 78 -22 78 2 0 24 -20 49
-44z"/>
<path d="M5265 5463 c-77 -99 -265 -269 -423 -378 -39 -27 -72 -55 -72 -61 0
-15 178 -186 230 -220 78 -51 138 -74 196 -74 56 0 100 18 148 60 154 136 167
358 26 430 -115 59 -242 -27 -244 -165 -1 -93 62 -161 125 -135 48 20 67 83
33 111 -15 12 -19 11 -32 -9 -19 -29 -40 -28 -48 2 -8 33 11 64 43 72 21 6 32
1 55 -25 23 -25 28 -41 28 -80 0 -126 -137 -161 -210 -54 -34 50 -36 167 -3
228 40 76 103 115 184 115 49 0 51 1 45 23 -14 47 -36 153 -36 175 0 32 -13
27 -45 -15z m-285 -342 c0 -10 -11 -35 -25 -55 -28 -42 -31 -61 -10 -69 18 -8
75 -86 75 -104 0 -8 -31 9 -76 42 -41 31 -83 59 -92 62 -44 16 -43 26 5 54 26
15 62 41 78 58 35 35 45 38 45 12z"/>
<path d="M1248 4590 c-22 -6 -23 -11 -25 -95 -3 -104 -6 -115 -30 -115 -27 0
-93 -37 -131 -74 -47 -44 -91 -139 -98 -211 -11 -107 35 -233 108 -295 39 -33
108 -70 130 -70 16 0 18 -11 18 -92 0 -51 4 -99 8 -106 7 -10 571 -12 2808
-10 l2799 3 3 102 3 102 31 6 c59 13 129 65 168 126 118 183 46 437 -144 508
l-51 19 -3 96 c-2 65 -7 98 -15 101 -18 7 -5552 11 -5579 5z m5562 -117 c0
-49 4 -93 8 -99 4 -6 23 -15 42 -19 81 -18 158 -88 191 -175 29 -77 24 -204
-10 -272 -38 -74 -131 -148 -186 -148 -38 0 -45 -19 -45 -117 l0 -93 -2780 0
-2779 0 -3 98 -3 97 -46 15 c-134 44 -209 149 -209 294 0 161 82 264 248 312
8 2 12 31 12 99 l0 95 2780 0 2780 0 0 -87z"/>
<path d="M1275 4511 c-3 -11 -5 -55 -3 -98 l3 -77 -48 -9 c-185 -31 -275 -266
-165 -432 35 -53 116 -105 164 -105 41 0 56 -20 53 -67 -1 -21 -2 -62 -1 -91
l2 -52 2753 2 2752 3 5 100 5 100 46 5 c123 14 225 166 205 304 -7 46 -45 130
-73 161 -31 35 -88 65 -136 71 l-46 7 -3 96 -3 96 -2752 3 c-2715 2 -2752 2
-2758 -17z m604 -339 c39 -28 42 -102 7 -137 l-26 -26 24 -37 c13 -20 26 -47
30 -59 4 -11 13 -24 21 -27 8 -3 15 -12 15 -21 0 -11 -12 -15 -48 -15 l-48 0
-30 73 c-22 55 -34 73 -51 75 -26 4 -37 -24 -31 -79 3 -26 9 -35 26 -37 12 -2
22 -10 22 -18 0 -11 -17 -14 -80 -14 -61 0 -80 3 -80 14 0 7 9 16 20 19 19 5
20 14 20 137 0 123 -1 132 -20 137 -11 3 -20 11 -20 19 0 29 209 26 249 -4z
m506 -132 c28 -84 56 -155 63 -158 7 -2 12 -10 12 -18 0 -11 -16 -14 -70 -14
-71 0 -87 10 -54 35 11 8 14 19 10 33 -6 19 -15 22 -66 22 -51 0 -60 -3 -66
-22 -4 -14 -1 -25 10 -33 39 -29 17 -35 -119 -35 -113 0 -135 2 -135 15 0 8 7
15 15 15 13 0 15 22 15 139 0 132 -1 139 -21 144 -44 12 -5 27 67 27 59 0 74
-3 74 -15 0 -8 -9 -15 -20 -15 -20 0 -20 -5 -18 -137 3 -137 3 -138 27 -141
13 -2 27 0 32 5 5 5 30 71 57 149 26 77 51 145 55 151 4 7 24 11 44 9 l36 -2
52 -154z m779 135 c48 -23 71 -69 70 -140 0 -79 -30 -137 -84 -165 -49 -25
-230 -30 -230 -6 0 8 7 16 15 20 12 4 15 30 15 141 0 113 -2 135 -15 135 -8 0
-15 7 -15 15 0 28 185 28 244 0z m290 14 c3 -2 28 -70 55 -149 28 -80 58 -150
66 -156 33 -24 15 -34 -60 -34 -60 0 -75 3 -75 15 0 8 7 15 15 15 8 0 15 8 15
18 0 35 -15 43 -71 40 -51 -3 -54 -5 -57 -30 -2 -20 1 -28 12 -28 9 0 16 -7
16 -15 0 -12 -13 -15 -60 -15 -46 0 -60 3 -60 15 0 7 6 18 14 22 7 4 34 73 61
153 26 80 52 148 57 151 12 8 64 6 72 -2z m668 -10 c4 -8 28 -75 53 -149 25
-74 52 -140 60 -146 32 -24 15 -34 -55 -34 -56 0 -70 3 -70 15 0 8 5 15 11 15
6 0 9 12 7 28 -3 25 -6 27 -57 30 -56 3 -71 -5 -71 -40 0 -10 7 -18 15 -18 8
0 15 -7 15 -15 0 -12 -13 -15 -60 -15 -46 0 -60 3 -60 15 0 7 6 18 14 22 7 4
35 73 61 153 27 80 52 148 58 151 16 11 71 2 79 -12z m441 10 c36 -10 38 -12
35 -47 -2 -29 -8 -38 -24 -40 -17 -2 -23 4 -27 27 -6 27 -11 31 -41 31 -18 0
-39 -7 -46 -15 -21 -26 2 -58 69 -93 67 -36 91 -63 91 -102 0 -38 -35 -89 -67
-96 -15 -3 -33 -7 -40 -9 -7 -2 -39 1 -73 7 l-60 10 0 44 c0 40 2 44 25 44 19
0 25 -5 25 -23 0 -31 18 -47 51 -47 44 0 59 10 59 40 0 30 -18 49 -72 73 -75
35 -108 105 -72 156 32 45 96 60 167 40z m636 -17 c38 -28 42 -102 7 -137
l-25 -25 35 -57 c19 -31 39 -62 44 -67 23 -23 10 -36 -36 -36 l-45 0 -34 75
c-26 57 -39 75 -55 75 -18 0 -20 -6 -20 -60 0 -53 2 -60 20 -60 11 0 20 -7 20
-15 0 -13 -13 -15 -77 -13 -74 3 -108 19 -63 31 19 5 20 13 20 141 0 129 -1
136 -20 136 -11 0 -20 7 -20 15 0 30 208 28 249 -3z m815 22 l46 -7 0 -53 c0
-51 -1 -54 -25 -54 -26 0 -29 3 -40 53 -5 25 -9 28 -42 25 -46 -4 -73 -39 -80
-102 -6 -52 11 -133 34 -158 30 -34 103 -14 103 28 0 8 10 14 25 14 21 0 25
-5 25 -27 0 -16 -4 -33 -8 -40 -20 -31 -150 -34 -197 -4 -44 28 -60 63 -63
141 -3 65 -1 80 20 110 24 37 97 80 134 80 12 0 42 -3 68 -6z m-3234 -69 c0
-58 -2 -65 -19 -65 -20 0 -24 8 -36 68 -5 25 -11 32 -31 32 l-24 0 0 -135 0
-135 30 -6 c17 -4 30 -13 30 -20 0 -11 -22 -14 -100 -14 -82 0 -100 3 -100 15
0 8 7 16 15 16 8 1 21 2 28 3 9 1 13 37 15 139 l3 137 -31 0 c-17 0 -30 -5
-30 -12 0 -7 -5 -30 -11 -50 -7 -27 -15 -38 -29 -38 -18 0 -20 7 -20 58 0 32
3 62 7 65 3 4 73 7 155 7 l148 0 0 -65z m1122 0 c3 -61 2 -65 -18 -65 -18 0
-23 8 -30 48 -8 41 -12 47 -37 50 l-28 3 3 -138 3 -138 28 -3 c15 -2 27 -9 27
-18 0 -11 -19 -14 -95 -14 -78 0 -95 3 -95 15 0 8 5 16 10 16 6 1 16 2 23 3 9
1 13 38 15 139 l3 138 -28 -3 c-25 -3 -29 -9 -37 -51 -7 -41 -11 -48 -30 -45
-11 2 -20 6 -18 11 1 4 2 32 2 62 l0 55 149 0 149 0 4 -65z m998 25 c0 -47
-23 -55 -42 -15 -9 21 -18 25 -55 25 l-43 0 0 -60 c0 -58 1 -60 26 -60 17 0
28 6 31 20 3 11 14 20 24 20 16 0 19 -8 19 -55 0 -48 -2 -55 -20 -55 -11 0
-20 6 -20 14 0 16 -34 31 -50 21 -9 -6 -15 -107 -6 -116 2 -2 27 -2 54 -1 43
3 51 7 56 28 5 18 13 24 32 24 24 0 26 -3 20 -31 -3 -17 -6 -38 -6 -45 0 -11
-27 -14 -135 -14 -109 0 -135 3 -135 14 0 8 7 16 15 20 12 4 15 30 15 141 0
113 -2 135 -15 135 -8 0 -15 7 -15 15 0 13 21 15 125 15 l125 0 0 -40z m510
25 c0 -8 -9 -15 -21 -15 -15 0 -19 -5 -15 -17 46 -147 74 -224 75 -207 1 11
15 60 31 109 34 101 36 115 15 115 -8 0 -15 7 -15 15 0 13 22 15 140 15 118 0
140 -2 140 -15 0 -8 -9 -15 -20 -15 -19 0 -20 -7 -20 -136 0 -127 1 -136 20
-141 11 -3 20 -12 20 -19 0 -11 -19 -14 -80 -14 -61 0 -80 3 -80 14 0 7 9 16
20 19 19 5 20 14 20 142 l0 136 -37 -3 -36 -3 -52 -150 c-52 -148 -53 -150
-84 -153 -35 -4 -27 -18 -97 171 -26 71 -53 131 -61 134 -7 3 -13 11 -13 19 0
11 18 14 75 14 60 0 75 -3 75 -15z m938 -28 c3 -33 1 -38 -15 -35 -10 2 -22
13 -28 26 -8 18 -18 22 -52 22 l-43 0 0 -50 c0 -27 0 -53 0 -57 0 -5 11 -9 25
-11 20 -3 28 2 32 17 3 12 11 21 18 21 14 0 26 -58 18 -89 -6 -23 -33 -29 -33
-7 0 14 -21 26 -46 26 -10 0 -14 -15 -14 -58 0 -32 2 -61 4 -63 3 -2 25 -3 51
-1 38 3 48 8 55 28 6 14 18 24 30 24 15 0 20 -7 20 -29 0 -61 0 -61 -146 -61
-107 0 -134 3 -134 14 0 7 9 16 20 19 19 5 20 14 20 141 0 129 -1 136 -20 136
-11 0 -20 7 -20 15 0 13 18 15 127 13 l127 -3 4 -38z"/>
<path d="M1745 4098 c0 -60 1 -63 26 -66 36 -4 59 22 59 67 0 45 -14 61 -55
61 l-31 0 1 -62z"/>
<path d="M2257 4060 c-10 -33 -17 -64 -17 -70 0 -5 18 -10 40 -10 22 0 40 5
40 10 0 19 -35 130 -41 130 -3 0 -13 -27 -22 -60z"/>
<path d="M3030 4020 l0 -140 29 0 c17 0 42 10 57 23 23 20 28 34 32 85 8 117
-18 172 -83 172 l-35 0 0 -140z"/>
<path d="M3386 4075 c-10 -33 -20 -68 -22 -77 -5 -15 2 -18 40 -18 25 0 46 1
46 3 0 1 -11 36 -24 77 l-23 75 -17 -60z"/>
<path d="M4051 4090 c-7 -25 -16 -58 -21 -74 -6 -16 -10 -31 -10 -32 0 -2 21
-4 46 -4 39 0 45 3 40 18 -3 9 -14 44 -24 77 l-19 60 -12 -45z"/>
<path d="M5070 4095 c0 -62 1 -65 24 -65 34 0 56 25 56 65 0 53 -8 65 -46 65
l-34 0 0 -65z"/>
<path d="M2536 3234 c-8 -21 1 -34 25 -34 14 0 19 7 19 25 0 29 -34 36 -44 9z"/>
<path d="M2817 3243 c-4 -3 -7 -15 -7 -25 0 -13 7 -18 25 -18 20 0 25 5 25 25
0 18 -5 25 -18 25 -10 0 -22 -3 -25 -7z"/>
<path d="M3161 3251 c-17 -11 -24 -72 -12 -104 23 -60 66 -6 54 68 -6 35 -21
48 -42 36z"/>
<path d="M4640 3156 c0 -23 22 -41 34 -29 3 4 6 17 6 30 0 16 -6 23 -20 23
-14 0 -20 -7 -20 -24z"/>
<path d="M5057 3243 c-9 -8 -9 -86 -1 -107 3 -9 14 -16 24 -16 21 0 30 19 30
65 0 18 -3 40 -6 49 -6 16 -35 22 -47 9z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
